.contact-area {
    display: flex;
    justify-content: space-between;
    padding: 3.75rem 0 3.75rem 0;
    background: var(--essential-white, #FFF);
    margin: 0 0 6.5rem 0;
}

.get-in-touch {
    width: 45%;
    padding: 2rem 0 2rem 7.5rem ;
}

.get-in-touch-title {
    color: var(--essential-black, #000A1A);

    /* Heading/H 3 */
    font-family: DM Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.4rem */
    text-transform: capitalize;

}

.get-in-touch-content p {
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding-left: 0.5rem;
    /* 1.6rem */
}

.phone-area {
    display: flex;
    padding-top: 2.5rem;
    /* justify-content: space-between; */
}

.phone-number {

}

.mail-area {
    display: flex;
    padding-top: 1rem;
    /* justify-content: space-between; */
}

.mail-id {}

.location-area {
    display: flex;
    padding-top: 1rem;
    /* justify-content: space-between; */
}

.location {}

.divide-line {
    width: 10%;
}

.social-media-area {
    width: 45%;
    padding: 2rem 0 1.81rem 0;
}

.social-media-title {
    color: var(--essential-black, #000A1A);

    /* Heading/H 3 */
    font-family: DM Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.4rem */
    text-transform: capitalize;
}

.social-media-sub-title {
    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding: 2.5rem 11.25rem 2.5rem 0;
    /* 1.6rem */
}

.social-media-icons {

}

@media (max-width: 992px) {
    .contact-area {
        flex-direction: column;
        align-items: center;
    }

    .get-in-touch {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        /* padding-left: 2.5rem; */
    }

    .get-in-touch-title {
        padding-left: 0rem;
    }

    .get-in-touch-content p {
        padding-left: 0.5rem;
    }

    .social-media-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        /* padding-left: 2.5rem; */
    }

    .social-media-sub-title {
        padding:  1rem 2rem 1rem 2rem;
    }
    .divide-line {
        height: 100%;
        width: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: rotate(90deg);
        margin: -6rem 0 -6rem 0;

    }
}