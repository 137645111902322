.project-tabs{
    margin: 0 0 4rem 0;
    padding: 0;
}
.project-area{
    padding: 6.25rem 0 4rem 0;
}

.project-area-pic-large{
    
    margin:0 1.5rem 1.5rem 0;
   
    overflow: hidden;
    height: 25rem;
    border-radius: 0.5rem;
  
}
.project-area-pic-small{
    
    margin:0 1.5rem 1.5rem 0;
    overflow: hidden;
    height: 17.5rem;
    border-radius: 0.5rem;
}

.project-area-pic-last{
    margin:0 1.5rem 0 0;
    overflow: hidden;
    height: 19.375rem;
    border-radius: 0.5rem;
}
.project-area-pic-large img{
    
    min-height: 100%;
    /* min-width: 100%; */
    max-width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    
}
.project-area-pic-small img{
    min-height: 100%;
    /* min-width: 100%; */
    max-width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}
.project-area-pic-last img{
    min-height: 100%;
    /* min-width: 100%; */
    max-width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}
.project-area-btn{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* vertical-align: top; */
}
.project-area-btn2{
    display: none;
}
@media (max-width: 767px) {
    .project-area{
        padding: 0rem 0 4rem 0;
    }
    .project-area-btn{
        display: none;
    }
    .project-area-btn2{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}