.gallary-row {
    margin: 1rem;
}

.gallary-area-title {
    color: var(--essential-black, #000A1A);
    text-align: center;
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.gallary-area {
    padding: 6rem 0 6rem 0;

}

.gallary-btn{
    display: flex;
    justify-content: center;
    margin: 3rem 0;
}

.gallary-pic-container img{
    width: 100%;
    height: 25rem;
    object-fit: cover;
}
.gallary-pic-container {
    height: 25rem;
    overflow: hidden;
    border-radius: 0.5rem;
    margin: 0 0 1.5rem 0;
}
@media (max-width: 767px) {

    .gallary-area {
        padding: 4rem 0 4rem 0;
    
    }

    .gallary-area-title {
        font-size: 1.5rem;
        text-align: center;
    }
    .gallary-row {
        
    }
    
    .gallary-pic-container {
        /* max-width: 20rem; */
        margin-bottom: 2rem;
    }
    .gallary-pic-container img{
        height: 25rem;
        /* width: 100%; */
    }
    .gallary-btn{
        margin: 0 0 0 0;
    }
    
}

.modal-close-btn{
    position: fixed;
    z-index: 100;
    top: 17%;
    right: 2rem;
    font-size: 2rem;
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.1rem 1rem;
    color: var(--essential-black, #000A1A);
    background-color: var(--primary-shade-1, #FFD700);
    border: none;
}
.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    /* background-color: rgba(0, 0, 0, 0.308); */
}