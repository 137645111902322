.testimonial-area{
    padding: 6rem 0 6rem 0;
}

.testimonial-area-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonial-area-title h1{
    color: var(--essential-black, #000A1A);
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: uppercase;
}
.testimonial-area-title-buttons{
    display:  flex;
    align-items: center;
    /* background-color: aqua; */
}
.testimonial-area-title-buttons button{
    background-color: var(--greyscale-shade-4, #F8F9FA);
}
.testimonial-next-button {
    background: var(--essential-black, #000A1A);
    border: 2px solid #000A1A;
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 1rem;
    transition: background-color 0.3s ease-in-out;
}
.testimonial-prev-button{
    /* background: var(--essential-black, #000A1A); */
    /* rotate: 180deg; */
    border: 2px solid #b8b9bb;
    /* background-color: aqua; */
    background-color: var(--essential-white, #ffffff);
    color: #000A1A;
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
}

.testimonial-next-button:hover, .testimonial-prev-button:hover{
    background-color: var(--primary-shade-1, #FFD700);
}
.testimonial-prev-button:focus, .testimonial-next-button:focus{
    background-color:var(--primary-shade-2, #FFE766);;
}

@media (max-width: 767px) {
    .testimonial-review-area{
        margin: 0.5rem;
    }
    .testimonial-area-title{
        margin-bottom: 2rem;
    }
    .testimonial-area-title p {
        font-size: 1.2rem;
    }
    .testimonial-area-title-buttons .testimonial-prev-button {
        margin-left: 1rem;
        height: 2.5rem;
        width: 2.5rem;
        /* margin-bottom: 2rem; */
    }
    .testimonial-next-button{
        height: 2.5rem;
        width: 2.5rem
    }
}