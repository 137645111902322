.box {
  /* animation-name: fade-in-from-right;
  animation-duration: 0.5s; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* max-width: 80%; */
  /* margin: 1rem; */
  /* border: 5px solid black; */
  border-radius: 0.75rem;
  min-height: 105%;
}

.photo-container {
  max-width: 80%;
  padding: 0 20% 10% 20%;
  background-color: var(--greyscale-shade-4, #F8F9FA);
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  display: flex;
  align-items: center;

}

.photo-box {
  max-width: 6.25rem;
  max-height: 6.25rem;
}

.photo-container img {
  max-width: 100%;

}

.title-text {
  color: var(--essential-black, #000A1A);
  text-align: center;

  /* Title/T 2 */
  font-family: DM Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 1rem 2rem 0.5rem 2rem;
}

p{
  margin-bottom: 0;
}

.body-text {
  color: var(--greyscale-shade-1, #52637A);
  text-align: center;

  /* Paragraph/P 2 */
  font-family: DM Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 1.4rem */
  width: 80%;
  text-align: center;
  /* padding: 0 0 0 0; */
}


@media (max-width: 767px) {

  .box {
    /* min-height: 20rem; */
    height: max-content;
    margin: 0 10% 10% 10%;
  }

  .photo-container {}

  .body-text {
    /* font-size: 0.75rem;
    width: 100%; */
  }
}