
.image-slider-area{

}

.image-slider-container {
    /* margin-top: 21.5rem; */
    /* width: 80%; */
    width: 100%;
    padding: 0 0 7.5rem 0;
    
}

.selected-image-show-area {
    display: flex;
    justify-content: center;
    /* height: 37.5rem; */
}

.selected-image-show-area img {
    width: 80vmax;
    max-height: 37rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.image-slider-thumbnails {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    margin-top: 1.5rem;
    width: 80%;
    /* overflow-y: visible; */
    margin-left: 9vmax;
    /* position: absolute; */
    /* overflow: auto; */
    /* padding: 1rem 0; */
}

.image-slider-thumbnails img {
    margin-bottom: 3.75rem;
    border-radius: 0.5rem;
    width: 30rem;
    height: 20rem;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-right: 1.5rem;
}

.image-slider-thumbnails img:last-child {
    margin-right: 0;
}

.image-slider-thumbnails::-webkit-scrollbar {
    display: none;
}

.image-slider-thumbnails img.selected {
    display: none;
}

.image-slider-thumbnails img.hide {
    opacity: 0;
    
    /* transition-delay: 0.5s; */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.selected-image-show-area img.hide {
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Style for the scroll bar */

/* Style for the scroll bar */
.scroll-bar {
   
    height: 3px;
    width: 70%;
    background-color: var(--greyscale-shade-2, #A8B1BC);
    border-radius: 5px;
    display: flex;
    align-items: center;
    /* stroke-width: 3px; */
    /* stroke: var(--essential-black, #000A1A); */
}

/* Style for the scroll bar thumb */
.scroll-bar-thumb {
    position: absolute;
    left: 0;
    top: 48.5%;
    height: 3px;
    width: 20%;
    background-color: var(--essential-black, #000A1A);
    border-radius: 5px;
    cursor: pointer;
}
.scrollbar-container{
    /* max-width: 100%; */
    position: relative;
    left: 9vmax;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.scroll-button{
    margin-left: 6.25rem;
    /* background-color: aqua; */
    padding: 1rem;
    
}
@media (max-width: 767px) {
    .image-slider-thumbnails{
        height: 13rem;
        width: 100%;
        margin-left: 5%;
        overflow-y: auto;
        overflow-x: visible;
        /* padding: 1rem; */
        padding-right: 5rem;
    }
    .image-slider-thumbnails::-webkit-scrollbar{
        display: none;
        /* height: 100%; */
    }
    .image-slider-thumbnails img{
        height: 100%;
        margin-bottom: 0;
    }
    .selected-image-show-area{
        height: 20rem;
    }
    .selected-image-show-area img{
        width: 90%;
        height: 100%;
    }
    .scrollbar-container{
        display: none;
        flex-direction: column;
        justify-content: center;
    }
    .scroll-bar{
        display: none;
        /* top: 49%; */
    }

    .image-slider-container{
        padding: 0 0 3rem 0;
    }
}
