
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
body {
  margin: 0;
  line-height: normal;
  background-color: var(--greyscale-shade-4, #F8F9FA) !important;

}
.not-found-area{
  padding: 7.5rem 0;
  text-align: center;
  font-size: 5rem;
  color: red;
  font-family: DM Sans;
  font-weight: 700;
}
:root {

  --greyscale-shade-4: #F8F9FA;
  --color-whitesmoke-100: #ebecef;
  --essential-black: #000a1a;
  --primary-shade-1: #ffd700;
  --essential-white: #fff;
  --greyscale-shade-1: #52637a;
}
a {
  text-decoration: none !important
}
