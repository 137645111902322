.why-choose-area{
    padding: 6rem 0 6rem 0;
    display: flex;
    flex-direction: column;
    height: max-content;
    background-color: var(--greyscale-shade-4, #F8F9FA);
}
.title-line{
    color: var(--essential-black, #000A1A);
    text-align: center;

    /* Heading/H 2 */
    font-family: DM Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 3rem */
    text-transform: uppercase;
    margin-bottom: 3rem;
}
.cardArea{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    margin-top: 2.75rem;
    /* max-width: 80%; */
}
@media (max-width: 767px) {
    .why-choose-area{
        /* margin-top: 3rem; */
        padding: 4rem 0 4rem 0;
    }
    .cardArea{
        flex-direction: column; 
    }
    .title-line{
        font-size: 1.5rem;
    }
    .why-choose-area-card-col{
        margin-bottom: 10%;
    }
}