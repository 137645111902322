.why-choose-area-2-about-pic img {
    display: flex;
    width: 33.33%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .why-choose-area-2-about-pic img {
        width: 100%; /* Set width to 100% for full width */
        flex-direction: column; /* Stack images vertically */
    }
}
