
.build-area {
    padding: 7.81rem 0;
    position: relative;
    z-index: 1;

}
.build-area-overlay{
    z-index: 0;
    background-color: var(--essential-black, #000A1A);
    opacity: 0.35;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.build-area-logo {
    /* width: 20.625rem; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: blue; */
    /* width: 10rem;
    height: 10rem; */
    padding-right: 3rem;
}

.build-area-logo img {

    max-width: 20.625rem;
    max-height: 20.625rem; 
    opacity: 0.5;
    
}

.build-area-text {}

.build-area-container {
    /* background-color: lightgray; */
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%); */

    /* background-image: url('../../Images/Service'); */
}

.build-area-text-title p {
    color: var(--essential-white, #FFF);

    /* Heading/H 2 */
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: capitalize;
}

.build-area-text-body p {
    color: var(--essential-white, #FFF);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding-top: 1.5rem;
    /* 1.6rem */
}

.build-area-btn {
    padding-top: 2.5rem;
}

@media screen and (max-width: 768px) {
    .build-area {
        padding: 3rem 0;
    }
    .build-area-logo {
        padding-right: 0;
    }

    .build-area-logo {
        /* padding-right: 0; */
        justify-content: center;
        padding-bottom: 3rem;
    }
    .build-area-logo img {
        max-width: 15.625rem;
        max-height: 15.625rem;
    }
    .build-area-text-title p {
        font-size: 1.625rem;
    }
    .build-area-text-body p {
        font-size: 0.875rem;
    }
    .build-area-btn {
        padding-top: 1.5rem;
    }

    .build-area-text-btn{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}