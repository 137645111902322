.complementary-btn2 a{
    text-decoration: none;
    color: var(--essential-black, #000A1A);
}

.Service-page-complementary {
    padding: 7.5rem 0;
}

.complementary-body {
    /* padding-right: 5rem; */
}

.complementary-subtitle p {
    color: var(--greyscale-shade-1, #52637A);

    /* Title/T 2 */
    font-family: DM Sans;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    
    /* 1.75rem */
}

.complementary-title {
    margin: 0 0 1.5rem 0;
}

.complementary-title p {
    color: var(--essential-black, #000A1A);

    /* Heading/H 2 */
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
}

.complementary-txt p {
    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.6rem */
    margin-bottom: 1rem;
}

.complementary-pic-container {
    /* width: 30.375rem; */
    height: 35rem;
    border-radius: 0.5rem;
    padding-left: 3rem;
    overflow: hidden;
    object-fit: cover;
    /* height: 100%; */
    /* background: var(--primary-shade-1, #FFD700); */
    
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
    /* flex-shrink: 0; */
}

.complementary-pic-container img {
    border-radius: 0.5rem;
    
    width: 100%;
    height: 100%;
    max-width: 30.375rem;
    /* max-height: none; */
    
    /* border-radius: 0.5rem; */
}

.complementary-btn, .complementary-btn2 {
    margin-top: 3rem;
    border: none;
    color: var(--essential-black, #000A1A);

    /* Button/B 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 1.6rem */
    text-transform: capitalize;
    padding: 0.75rem 2.5rem;
    border-radius: 0.25rem;
    background: var(--primary-shade-1, #FFD700);

    /* 2 */
    box-shadow: 0px 20px 80px 0px rgba(0, 10, 25, 0.04);
}
.complementary-btn2{
    display: none;
}
@media (max-width: 768px){
    .Service-page-complementary {
        padding: 4rem 0;
    }
    .complementary-btn{
        display: none;
    }
    .complementary-btn2{
        margin: 0 1rem;
        display: block;
        padding: 0.5rem 2rem;
    }
    .complementary-body{
        padding: 0 1rem;
    }
    .complementary-txt{
        /* display: none; */
        padding: 0 1rem;
        text-align: justify;
    }
    .complementary-pic-container{
        /* display: none; */
        padding: 2rem 1rem;
        /* padding-left: 0; */
        height: 25rem;
        /* width: 50%; */
    }
    .complementary-pic-container img{
        width: 100%;
    }
}