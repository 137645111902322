.testimonial-area-card-full{
    position: relative;
    /* height: 100%; */
    /* height: max-content; */
}
.testimonial-area-card-text {
    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* padding: 1.6rem; */
    
}
.testimonial-area-card-container{
    position: relative;
    z-index: 1;
    background-color: var(--essential-white, #FFF);
    padding: 2.5rem;
    border-radius: 0.5rem;
    margin: 2rem 0 2rem;
    min-height: 25rem;
    
}

.reviewer-profile {
    /* border-radius: 31.25rem; */
    position: absolute;
    z-index: 2;
    bottom: -3.35rem;
    left: 10%;
    padding: 1rem;
    /* margin-top: 9rem; */
    border-radius: 4.75rem;
    /* border: 8px solid var(--greyscale-shade-4, #F8F9FA); */
    background: var(--essential-white, #FFF);
    border: 8px solid var(--greyscale-shade-4, #F8F9FA);
    /* display: flex; */
    /* max-width: 30.75rem; */
    width: 80%;
    height: 6.75rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* flex-direction: row; */
}
.pic-name-title{
display: flex;
flex-wrap: nowrap;
}
.reviewer-details{
    margin-top: .2rem;
    padding-left:0.75rem;
}

.reviewer-name {
    color: var(--essential-black, #000A1A);

    /* Title/T 2 */
    font-family: DM Sans;
    font-size: 120%;
    font-style: normal;
    font-weight: 500;
    /* line-height: 1.75rem; */
    line-height: 140%;
    margin: 0;
    /* text-align: center; */
    /* 1.75rem */
}

.reviewer-title {
    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    margin: 0rem;
    line-height: 160%;
    /* text-align: center; */
    /* line-height: 1.4rem; */
    /* 1.4rem */
}
.reviewer-img img{
    max-width: 3.75rem;
    border-radius: 50%;
}
.card{
    border: none;
}
.reviewer-profile .col-7{
    padding: 0;
}
.reviewer-profile .col-3{
    padding-right: 0;
    max-width: 4.75rem;
}


@media (max-width: 995px){

    .reviewer-name{
        font-size: 1rem;
    }
    .reviewer-title{
        font-size: 0.75rem;
        /* text-align: center; */
    }

    .testimonial-area-card-text{
        margin-bottom: 3rem;
    }

}
@media (max-width: 767px) {

    .testimonial-area{
        padding: 4rem 0;
    }

    .testimonial-area-title p{
        padding-left: 1.5rem;
    }

    .testimonial-area-card-full{
        margin-bottom: 4rem;
    }
    .testimonial-area-card-container{
        padding: 2rem;
        margin-top: 0;
        min-height: 100%;
    }

    .testimonial-prev-button {
        margin-left: 1rem;
    }

    .reviewer-profile{
        margin-top: 2rem;
        bottom: -2.9rem;
        left: 10%;
        width: 80%;
        height: 5rem;
        height: max-content;
    }
    .reviewer-img img{
        width: 3rem;
    }
    .reviewer-name{
        font-size: 1rem;
    }
    .reviewer-title{
        font-size: 0.75rem;
        /* text-align: center; */
    }
    .dotdot-area{
        /* width: 2rem;
        height: 1.25rem; */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -0.5rem;
    }
}

.dotdot-area{
    /* width: 2rem;
    height: 1.25rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.5rem;
    margin-right: 1.3rem;
}