.trusted-area {
    padding: 6rem 0 6rem 0;
}

.trusted-area-title {
    color: var(--essential-black, #000A1A);
    text-align: center;
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: uppercase;
    padding: 0 20% 3.15rem 20%;
}
.trusted-area-logos {
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 2rem 4rem;
    position: relative; /* Add position relative */
    z-index: 1; /* Ensure it's above the pseudo-element */
}
.trust-single-logo{
    /* padding-right: 10%; */
    /* margin: 0 15%; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.trust-single-logo img{
    /* margin-right: 5rem; */
    width: 100%;
    
}
.trusted-area-logos::before {
    border-radius: 1rem;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--essential-white, #ffffff);
    transform: skew(10deg); /* Skew in the opposite direction */
    z-index: -1; /* Place it below the content */
}

.logo-area{
    display:flex;
    align-items:center;
    justify-content:center;
}
@media (max-width: 767px) {

    .trusted-area {
        padding: 4rem 0 4rem 0;
    }

    .trusted-area-logos {
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
        width: 70%;
        padding: 1rem 2rem;
    }

    .trusted-area-title {
        font-size: 1.5rem;
        padding: 0 2rem 2rem 2rem;
    }
    .trust-single-logo{
        margin: 1rem 0;
    }
    .trust-single-logo{
        margin-right: 0.5rem;
        /* transform: scaleX(.8); */
    }

    .logo-area{
        /* transform: scaleX(1.2); */
    }
}