
.modal-area{
    position: fixed;
    top: 3rem;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-area img{
    position: relative;
    
    object-fit: cover;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    z-index: 10;
}
.main-Image img{
    
    max-width: 90vw;
    max-height: 80vh;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.modal-buttons{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 101;
}
.modal-buttons button{
    background-color: transparent;
    border: none;
    font-size: 2rem;
    /* color: white; */
    cursor: pointer;
}
.modal-buttons button:hover{
    /* color: #f1f1f1; */
}
.modal-buttons button:focus{
    outline: none;
}
.modal-next-btn{
    margin-left: 2rem;
}
.modal-prev-btn{
    margin-right: 2rem;
}
.modal-area .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 100;
}
.modal-prev-btn .testimonial-prev-button:hover{
    /* background-color: var(--primary-shade-1, #FFD700) !important; */
    
}