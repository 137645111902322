.work-area {
    padding: 6rem 0 6rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.work-area-title {
    color: var(--essential-black, #000A1A);
    text-align: center;
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: uppercase;
    margin-bottom: 3rem;
}

@media (max-width: 767px) {
    .work-area{
        padding: 4rem 1rem 4rem 1rem;
    }
    .work-area-title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 0;
    }
}