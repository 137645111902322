.service-page-card-1 {
    padding: 7.5rem 0;
    /* position: relative; */
}

.service-page-card-1-container {
    position: relative;
    /* background-color: aqua; */
}

.service-page-card-1-pic-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43.125rem;
    min-width: 60%;
    max-width: 60%;
    height: 35rem;
    border-radius: 0.5rem;
    /* position: relative; */
    overflow: hidden;
    /* background-color: aqua; */
}

.service-page-card-1-pic-container img {
    object-fit: cover; /* This ensures the image fills the whole container */
    width: 100%; /* Set the width to 100% to make it full-width */
    height: 100%; /* Set the height to 100% to make it full-height */
    max-width: none; /* Remove any maximum width constraint */
    max-height: none; /* Remove any maximum height constraint */
}

.service-page-card-1-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--essential-white, #FFF);
    border-radius: 0.5rem;
    padding: 0 4.5rem;
    position: absolute;
    right: 0;
    top: 1.88rem;
    width: 40.25rem;
    min-width: 60%;
    max-width: 80%;
    /* min-height: 31.25rem; */
    height: 90%;
}

.service-page-card-1-title{
    margin: 0 0 3rem 0; 
}
.service-page-card-1-title p{
    color: var(--essential-black, #000A1A);

    /* Heading/H 2 */
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: capitalize;
}
.service-page-card-1-txt{
    margin: 0 0 1rem 0;
}
.service-page-card-1-txt p{
    color: var(--greyscale-shade-1, #52637A);

/* Paragraph/P 1 */
font-family: DM Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 1.6rem */
}
@media (max-width: 992px){
    .service-page-card-1-text-container{
        padding: 1rem 2.5rem;
        margin: 0;
        /* right: 5%; */
    }
}
@media (max-width: 767px) {
    .service-page-card-1-container{
        display: flex;
        flex-direction: column;
        margin: 0 0 -45% 0;
    }
    .service-page-card-1-pic-container{
        max-width: 100%;
        max-height: 100%;
        /* height: 100%; */
        /* margin-bottom: 10rem; */
    }
    
    .service-page-card-1-text-container p{
        height: 150%;
        width: 100%;
    }
    .service-page-card-1-text-container{
        width: 80%;
        /* right: 10%; */
        left: 10%;
        padding: 2rem 2.5rem;
        top: -10rem;
        /* height: 100%; */
        position: relative;
        
    }
 
    .service-page-card-1-title{
        margin: 0 0 2rem 0; 
    }
    .service-page-card-1-title p{
        font-size: 1.5rem;
    }
    .service-page-card-1-txt{
        margin: 0 0 1rem 0;
    }
    .service-page-card-1-txt p{
        font-size: 1rem;
    }

    .service-page-card-1-text-container {
        padding: 2rem 1.5rem 0 1.5rem;
    }

    .service-page-card-1 {
        padding: 4rem 0;
    }
}