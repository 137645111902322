.single-project-header{
    margin: 10rem 0 2.62rem 0;
}
.single-project-header .title{
    color: var(--essential-black, #000A1A);

/* Heading/H 2 */
font-family: DM Sans;
font-size: 2.625rem;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 3.15rem */
text-transform: capitalize;
}

.single-project-header .subtitle{
    color: var(--greyscale-shade-1, #52637A);

    /* Title/T 1 */
    font-family: DM Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 2.1rem */
}
.single-projext-page-details-container{
    padding: 3rem;
    background-color: var(--essential-white, #FFF);
    border-radius: .75rem;
}

.single-projext-page-details-title p{
    color: var(--essential-black, #000A1A);

/* Title/T 1 */
font-family: DM Sans;
font-size: 1.5rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 2.1rem */
margin-bottom: 1.5rem;

}
.single-projext-page-details-body p{
    margin-bottom: 1rem;
    color: var(--greyscale-shade-1, #52637A);

/* Paragraph/P 1 */
font-family: DM Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 1.6rem */
}

.project-consultation-form{
    background-color: #FFF;
    margin-top: 7rem;
}

@media (max-width: 993px){
    .project-contact-us{
        display: none;
    }

}

@media screen and (max-width: 768px){
    .single-project-header{
        margin: 7rem 0 2.62rem 0;
    }
    .single-project-header .title{
        font-size: 1.5rem;
    }
    .single-project-header .subtitle{
        font-size: 1rem;
    }
    .single-projext-page-details-container{
        padding: 2rem;
    }
    .single-projext-page-details-title p{
        font-size: 1.25rem;
    }
    .single-projext-page-details-body p{
        font-size: 1rem;
    }
    .single-project-page-container .contact-us-form-container{
        margin-top: 3rem;
    }

    .project-consultation-form{
        background-color: #FFF;
        margin-top: 4rem;
    }
}