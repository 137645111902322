.customBtn{
    display: inline-flex;
    padding: 0.5rem 1.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    border: none;
    border-radius: 2.5rem;
    background: var(--primary-shade-1, #FFD700);
    font-family: DM Sans;
    /* font-size: 2rem; */
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.5rem */
}
.customBtn:hover{
    transition: all 0.3s ease-in-out;
    background: #ebc90c;
}
@media (max-width: 767px) {
    .customBtn{
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
}