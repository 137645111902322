.Blogs-area-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Blogs-area{
    padding: 6rem 0 6rem 0;
}

.blogs-row{
    margin-bottom: 3.75rem;
}

@media (max-width: 767px) {
    .Blogs-area{
        padding: 4rem 0 4rem 0;
    }
    .blogs-row{
        margin-bottom: 0rem;
    }
}