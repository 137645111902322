.service-area-container{
    padding: 3rem 0 7.6rem 0;
}
.service-title-area{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.service-title-text{
    color: var(--essential-black, #000A1A);
    font-family: DM Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
    text-transform: capitalize;
    /* margin-left: 10%; */
}
.service-cards-area{
    /* display: flex; */
    /* margin: auto; */
    /* overflow: hidden; */
    /* padding: 0 5 0 5%; */
    /* max-width: 50%; */
    
}
@media (max-width: 767px) {

    .service-area-container{
        padding: 4rem 0 4rem 0;
    }

    .service-cards-area{
        flex-direction: column;
        align-items: center;
        
    }
    .service-title-area {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
    }
    .service-title-area p{
        font-size: 1.5rem;
        text-align: center;
    }
}