.footer-area {
    background-color: var(--essential-black, #000A1A);
    /* padding: 2rem 0 2rem 0; */
}

.footer-area p {
    color: var(--essential-white, #ffffff);
}

.footer-row {
    padding: 6rem 0 3.4rem 0;
}

.copyright-area p {
    color: var(--essential-white, #FFF);
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
    text-align: center;
    margin-bottom: 0;
}
.copyright-area{
    padding: 1.5rem 0 1.5rem 0;
}

.footer-line {
    width: 100%;
}

.footer-col1 {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* padding-left: 1rem; */
}

.footer-area1 {
    max-width: 11rem;
    padding-left: 1rem;
}

.footer-area1 img{
    margin-top: 2rem;
}
.footer-logo {
    margin-bottom: 1.75rem;
    color: var(--essential-white, #FFF);

    /* Heading/H 3 */
    font-family: DM Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.4rem */
    text-transform: capitalize;
}

.footer-subtitle {
    margin-bottom: 0.75rem;
    color: var(--essential-white, #FFF);

    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.4rem */
}

.footer-title {
    color: var(--essential-white, #FFF);

    /* Button/B 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 1.6rem */
    text-transform: capitalize;
    margin-bottom: 1.5rem;
    padding-right: 1rem;
}

.footer-col2 {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding-left: 10rem;

}

.footer-logo{
    width: 10rem
}
.social-logo{
    display: flex;
    justify-content:flex-start;
    /* align-items: flex-start; */
}
.social-logo img{
    margin: 0.5rem 0.75rem 0 0;
    height: 2rem;
    width: 2rem;
    background-color: #FFF;
    border-radius: 50%;
    
}
@media (max-width: 767px) {
    .footer-logo {
        font-size: 1rem;
    }

    .footer-logo{
        width: 6rem
    }

    .footer-subtitle {
        font-size: 0.6rem;
    }

    .footer-title {
        font-size: 0.75rem;
    }

    .footer-area1 {
        max-width: 6rem;
    }
    .footer-area2{
        padding-left: 2rem;
    }

    .footer-gap {
        display: none;
        width: 0;
    }

    .footer-area3 {
        /* padding: .25rem; */
        margin-right: 1.5rem;
    }

    .footer-col2{
        padding-left: 4rem;
    }
}