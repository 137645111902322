.consultation-form {
    padding: 5rem 0 5rem 0;
    background-color: var(--essential-white, #ffffff);
    position: relative;
}

.consultation-area-title {
    color: var(--essential-black, #000A1A);

    /* Heading/H 2 */
    font-family: DM Sans;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 50.4px */
    text-transform: capitalize;
    text-align: center;
    padding-bottom: 3.75rem;
}

.consultation-area-subtitle {
    color: var(--greyscale-shade-1, #52637A);
    text-align: center;

    /* Title/T 2 */
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
}

.consultation-input {
    /* border: none; */
    /* border-bottom: 2px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */

}

.consultation-input-title {
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 0.5rem;
    /* 1.6rem */
}

.consultation-input input:focus {
    outline: none;
}

.consultation-input input {
    border: none;
    border-bottom: 1px solid var(--greyscale-shade-2, #A8B1BC);
    width: 80%;
    margin-bottom: 2.5rem;
}

.consultation-input .project-description-input {
    margin: 0 0 1rem 0;
}

.consultation-area-radio-group-title {
    margin: .5rem 0 0.5rem 0;
}

.consultation-area-radio-group-title p {
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    text-align: left;
}

.consultation-area-radio-group {
    width: 5rem;
    display: flex;
    flex-direction: row;
}

.radio-label {
    margin-right: 2rem;
}

.consultation-area-button {
    border-radius: 6px;
    background: var(--primary-shade-1, #FFD700);
    border: none;
    padding: 0.7rem 2rem;
    margin: 2.5rem 0 0 0;
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
}

::placeholder {
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.6rem */
}

.consultation-form-close-btn button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.consultation-form-close-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: none;
}

@media (max-width: 768px) {
    .consultation-form {
        padding: 4rem 2rem 4rem 2rem;
    }

    .consultation-area-title {
        font-size: 1.5rem;
    }

    .consultation-area-subtitle {
        font-size: 1rem;
    }

    .consultation-area-button {
        /* margin-top: 0; */
    }

    .consultation-area-button .customBtn {
        /* margin: 0; */
    }

    .consultation-form-close-btn {
        /* display: block; */
    }
}