.single-location-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    
}
.single-location-container img{
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    /* margin: auto; */
}
.single-location-text {
    margin: auto;
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.4rem */
}