/* Base styles */
.cta-container {
    overflow: hidden;
    background-position: left;
    position: relative;
    text-align: left;
    /* margin-bottom: 6rem; */
    padding: 6rem 0;
}
.half-area{
    width: 50%;
}
.cta-container::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url('../../Images/cta-background.webp');
    border: 1.5rem solid var(--essential-white, #ffffff);
    border-radius: 2rem;
    max-height: 26rem;
}

.cta-area-col2::after {
    position: absolute;
    z-index: 1;
    content: "";
    border: 1.5rem solid var(--essential-white, #ffffff);
    width: 100%;
    height: 100%;
    max-height: 26rem;
    background-color: var(--primary-shade-1, #FFD700);
    transform: translateX(25%) skew(-30deg);
    border-radius: 2rem;
}

.cta-area-col2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 26rem;
}

.cta-area-content {
    padding: 6rem;
    
}

.cta-area-title {
    margin-left: 10%;
    position: relative;
    z-index: 2;
    color: var(--essential-black, #000A1A);
    font-family: DM Sans;
    font-size: 170%;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
    /* text-align:right; */

}

.cta-area-subtitle {
    position: relative;
    z-index: 2;
    color: var(--essential-black, #000A1A);
    font-family: DM Sans;
    font-size: 120%;
    font-weight: 500;
    line-height: 160%;
    margin-top: 1rem;
    /* text-align: justify; */
}

.cta-area-button {
    position: relative;
    z-index: 2;
    margin-left: -20%;
    margin-top: 2rem;
}

/* Responsive adjustments for iPad */
@media (max-width: 1025px){
    .cta-area-title {
        font-size: 1rem;
    }
    .cta-area-subtitle {
        font-size: .75rem;
    }
}
/* @media (max-width: 1024px) {
    .cta-area-col2::before {
        transform: translateX(50%) skew(35deg);
    }
    .cta-area-col2{
        align-items: end;
        justify-content: end;
    }
    .cta-area-content {
        padding: 4rem 0 1rem 0;
    }
    .cta-area-title {
        margin-left: -15%;
        font-size: 1rem;
    }
    .cta-area-subtitle {
        font-size: .75rem;
        margin-left: 5%;
    }
    .cta-area-content {
        margin: 1rem;
    }
} */

/* Responsive adjustments for phones */
@media (max-width: 767px) {
    .cta-area-col2::after {
        transform: translateX(50%) skew(35deg);
    }
    .cta-area-col2{
        align-items: end;
        justify-content: center;
        padding: 0;
    }
    .cta-container {
        /* padding-right: 2rem; */
    }
    .cta-container::after {
        border-radius: 1rem;
        margin-right: 2rem;
    }
    .cta-area-content {
        padding: 1rem 0rem 14rem 0rem;
    }
    .cta-area-title {
        padding-top: 10rem;
        margin-left: -15%;
        font-size: 1rem;
    }
    .cta-area-subtitle {
        font-size: .75rem;
        margin-left: 5%;
    }
    .cta-area-content {
        margin: 1rem;
    }
}

/* Additional media queries for other devices */
