.customnav{
    /* background-image: linear-gradient(to top, #ffffff00 10% , #00000000 20% ,  #00000075, #000000 ); */
    background-color: #000A1A;
    ;
    min-height: 3rem;
}

.customnav ,.navlinks a{
    color: white;
    text-decoration: none;
    font-family: var(--heading-h-3);
    /* border: solid 1px transparent; */
}
.logo{
    color: var(--essential-white, #FFF);
    font-family: DM Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    
}
.musebath-logo{
    filter: drop-shadow(1px 1px 1px #000000);
    width: 12.75rem;
}
.logo:hover{
    transition: all 0.3s ease-in-out;
    color: #FFD700 !important;
}
.navlinks{
    display: inline-flex;
    align-items: flex-start;
    gap: 2.5rem;  
    margin-left: 1px;
}
.navlinks a{
    padding: 0.1rem 0.5rem;
    font-size: 1rem;
}
.navlinks a:hover{
    transition: all 0.3s ease-in-out;
    color: #FFD700;
}
.navlinks a:focus{
    border: solid 1px #FFD700;
    transition: border-radius 0.3s ease-in-out;
    border-radius: 2rem;
}
span.navbar-toggler-icon{
    background-image: url('../../Images/menu.png');
    /* background-color: #FFD700; */
}

@media screen and (max-width: 993px){
    .customnav Container{
        padding: 0 0rem;
    }

    .navlinks{
        gap: 0rem;  
        margin-left: 1px;
    }
}

@media screen and (max-width: 767px){
    .customnav .mx-auto{
        padding-top: 1rem;
    }
    .mx-auto.navlinks.navbar-nav{
        margin-bottom: 3rem;
    }
}