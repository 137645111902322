.blog-hero-part {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
}

/* .blog-hero-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
} */

.blog-hero-overlay{
    background-color: #00000033;
    /* opacity: 0.2; */
    position: absolute;
    height: 100% !important;
    width: 100%;
    z-index: 2;
}
.blog-hero-text-area {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
}

.blog-hero-text-area p {
    color: var(--essential-white, #FFF);
    text-align: center;
    font-family: DM Sans;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 4.95rem */
    text-transform: capitalize;
    
}
.blog-hero-text-area{
    margin-top: 15rem;
    margin-bottom: 15rem;
}

.estimate-btn button{
    border: none;
    background-color: transparent;
    padding: 0;

}
.estimate-btn{
    position: absolute;
    top: 40%;
    left: 0;
    width: 8.75rem;
    z-index: 5;
}
.estimate-btn-text{
    position: absolute;
    top: 31.5%;
    left: 0;
    padding-left: .7rem;
   
    /* padding-bottom: 7rem; */
}
.free-text{
    color: var(--essential-black, #000A1A);
    font-family: DM Sans;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 2.1rem */
    text-transform: capitalize;
}
.estimate-text{
    color: var(--essential-black, #000A1A);

    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 1.4rem */
}

.overlay-btn-border{
    position: absolute;
    top: 10%;

}
.blog-hero-part-overlay{
    /* border-radius: 0.5rem; */
    position: absolute;
    top: 50%;
    left: 8.5rem;
    width: 31.5rem;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    
}
.blog-hero-part-overlay .consultation-area-title{
    font-size: 1.5rem;
    
}
.blog-hero-part-overlay .consultation-area-subtitle{
    font-size: 0.875rem;
}
.blog-hero-part-overlay .consultation-form{
    padding: 3rem;
    border-radius: .5rem;
    box-shadow: 2px 2px 10px var(--greyscale-shade-1, #52637A);;
}
.blog-hero-part-overlay .consultation-form-close-btn{
    display: block;
}

@media (max-width: 768px) {
    .blog-hero-part{
        margin: 4rem 0 4rem 0;
    }
    .blog-hero-text-area p{
        font-size: 2.5rem;
    }

    .blog-hero-text-area{
        margin: 8rem 0 7rem 2rem;
    }

    .estimate-btn{
        /* position: absolute; */
        top: 40%;
        left: -1.2rem;
        /* width: 8.75rem; */
        /* z-index: -1; */
    }

    .estimate-btn img{ 
        width: 70%;
    }

    .free-text{
        font-size: 1rem;
    }
    .estimate-text{
        font-size: 0.675rem;
    }

    .estimate-btn-text{
        /* position: absolute; */
        top: 31.5%;
        left: 0;
        padding-left: 1.7rem;
        /* padding-bottom: 7rem; */
    }
    .blog-hero-part-overlay .consultation-form {
        padding: 2rem;
    }

    .blog-hero-part-overlay{
        top: 29rem;
        left: 3rem;
        width: 20rem;
    
    }

    .blog-hero-part-overlay .consultation-area-title {
        font-size: 1.4rem;
        padding-bottom: 2rem;
    }

    .blog-hero-part-overlay .consultation-area-subtitle {
        font-size: 0.775rem;
    }

    .blog-hero-part-overlay .d-flex {
        flex-direction: column;
    }

    .blog-hero-part-overlay .consultation-area-button {
        padding: 0.7rem 1.5rem;
        margin-top: 1.rem;
    }
}