.article-area{

    padding: 6rem 0 6rem 0;
}

.article-area-title {
    color: var(--essential-black, #000A1A);
    text-align: center;
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: uppercase;
    margin-bottom: 3.15rem;
}

.article-cards{
    margin: 1rem;
    /* max-width: 90%; */
}

.article-area-btn{
    margin-top: 3.75rem;
    display: flex;
    justify-content: center;
}
.article-area-card-button button{
    background-color: var(--essential-white, #ffffff);
}
@media (max-width: 767px) {
    .article-area-title {
        font-size: 1.5rem;
        text-align: center;
    }
    .article-area{
        padding: 4rem 0;
    }
    
}