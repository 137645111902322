.service-area-2{
    padding: 6rem 0 6rem 0;
}
.Service-area-2-title {
    color: var(--essential-black, #000A1A);
    text-align: center;
    font-family: DM Sans;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 3.15rem */
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.service-area-text {
    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.6rem */
}

.Location-title {
    color: var(--essential-black, #000A1A);

    /* Button/B 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 1.6rem */
    text-transform: capitalize;
    margin: 1rem 0 1rem 0;
}

.single-location {
    display: flex;
}

.single-location img {
    width: 1rem;
    height: 1rem;
}

.map-area{
    /* position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem; */
    overflow: hidden;
    border-radius: 1rem;
    height: 30.45481rem;

    /* border: 1rem solid red; */
    background-color: aquamarine;
}

@media (max-width: 767px) {
    .service-area-2{
        padding: 4rem 0 4rem 0;
    }
    .service-area-text {
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
        padding: 2rem;
    }
    .Service-area-2-title {
        font-size: 1.5rem;
        text-align: center;
    }
    /* .single-location {
        flex-direction: column;
        align-items: center;
    }
    .single-location-text {
        text-align: center;
    } */
    .service-locations{
        padding:  0.5rem;
    }
    .Location-title {
        text-align: center;
    }
}