.contact-us-form-container {
    width: 100%;
    /* height: 100%; */
    padding: 3rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: var(--essential-white, #FFF);
    border-radius: 0.75rem;
}

.contact-us-form-title {
    color: var(--essential-black, #000A1A);

    /* Title/T 1 */
    font-family: DM Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 2.1rem */
}

.contact-us-form-subtitle {
    color: var(--greyscale-shade-1, #52637A);

    /* Button/B 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 1.4rem */
    text-transform: capitalize;
}

.contact-us-form-body .form-control {
    /* width: 100%; */
    height: 3.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--greyscale-shade-3, #A4AEB8);

    padding: 0 1rem 0 1rem;
    margin: 1rem 0 1rem 0;
}

.contact-us-form-body .form-control::placeholder {
    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.4rem */
}
.contact-us-form-body .message-area{
    padding: 1rem;
    height: 7.8rem;
}


.contact-us-submit-btn {
    margin-right: auto;
    border: none;
    text-decoration: none;
    width: 100%;
    max-width: 25rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background: var(--primary-shade-1, #FFD700);
}