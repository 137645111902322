/* CustomTextInput.css */
.formArea {
    display: flex;
    /* background-color: cyan; */
    background-color: var(--greyscale-shade-4, #F8F9FA);
    /* border: 0.5rem solid var(--greyscale-shade-4, #F8F9FA); */
    border-radius: 3.125rem;
    padding-left: 1rem;
    width: 80%;
    margin-top: 10%;
    /* margin-top: 10.62rem; */
    height: 4.125rem;
   
}

.custom-text-input {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-radius: 3.125rem;
    background-color: #000a1a;
    border: 0.5rem solid var(--greyscale-shade-4, #75bf7c);
    /* max-width: 63.75rem; */
    /* width: 90%; */
    height: 4.125rem;
    width: 100%;
    /* position: relative; */
    /* Added position relative */
}

.custom-text-input input {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
    width: 80%;
    /* margin-left: 10px; */
    border: none;
    background-color: #000a1a;
    border-radius: 3.125rem;
    color: var(--essential-white, #FFF);
    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.4rem */
    /* Added text color */
    padding: 10px 0px 10px 20px;
    /* Added padding */
    /* max-width: 20%; */
    /* Adjust the width as needed */
}

.custom-text-input input:focus {
    outline: none;
    background-color: transparent;
}

.custom-text-input input::placeholder {
    color: var(--essential-white, #FFF);
    /* Paragraph/P 2 */
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 1.4rem */
}

.form-header{
    padding-left: 1rem;
    margin-top: 5px;
    /* padding: 10px 0 -10px 0 ; */
    color: var(--essential-black, #000A1A);

/* Title/T 2 */
font-family: DM Sans;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 1.75rem */
margin-right: 5rem;
}
 .form-header p{
     margin-bottom: 0rem;
     /* margin-top: 10px; */
 }


.btn-for-input {
    margin-left: auto;
    width: 3.125rem;
    height: 3.125rem;
    border: 0.5rem solid #000a1a;
    border-radius: 50%;
    background: var(--primary-shade-1, #ffd700);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-line {
    height: 2rem;
    /* margin: 0 1rem; */
    /* Added margin to separate inputs */
}

.mobile-consultation {
    display: none;
}

/* Mobile styles */
@media (max-width: 767px) {
    .form-header {
        display: none;

    }

    .custom-text-input {
        /* display: none; */
        /* background-color: green; */
        height: 0;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        border: none;
        /* height: 10rem; */
        /* background-color: none; */
        /* margin-bottom: 1rem; */
    }

    .custom-text-input input {
        width: 100%;
        /* border: none; */
        margin-top: 10px;
    }

    .input-line {
        display: none;
    }

    .btn-for-input {
        margin-top: 1rem;
        width: fit-content;
        border-radius: 1rem;
    }

    .mobile-consultation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    .formArea {
        border: none;
        padding-left: 0;
        margin-top: 2rem;
        margin-bottom: 20rem;
        flex-direction: column;
        background-color: transparent;


    }
}