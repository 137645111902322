.card2-img-container {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* width: 100%; */
    border-radius: 0.5rem;
    cursor: pointer;
}

.card2-container-img {
    height: 28.75rem;
    border-radius: 0.5rem;
}

.card2-body-underline {
    height: 0.25rem;
    width: 2rem;
    margin-left: 1rem;
    padding: 0;
    display: none;
    padding-bottom: 2rem;
}

.card2-body-link a{
    text-decoration: none;
}
.card2-container:hover {
    transform: translateY(-3.62rem);
    transition: all 0.5s ease-in-out;

    .card2-overley {
        display: none;
    }

    .card2-body {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        display: block;
        color: var(--essential-black, #000A1A);
    }

    .card2-title {
        display: none;
    }

    .card2-body-title {
        transform: translateY(0);
        transition: all 0.3s ease-in-out;
        color: var(--essential-black, #000A1A);
        font-family: DM Sans;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: capitalize;
        padding: 0 0 0.62rem 0;
    }

    .card2-body-link {
        transition: all 0.1s ease-in-out;
        transition-delay: .3s;
        /* color: var(--greyscale-shade-1, #52637A); */
        color: var(--essential-black, #000A1A);

        /* Paragraph/P 1 */
        font-family: DM Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 1.6rem */
        /* border-bottom: 2px solid #52637A; */
        border-bottom: 2px solid var(--essential-black, #000A1A);

        &:hover {
            /* border: 2px solid #52637A; */
            /* background-color: #9e896a24; */
            color: var(--essential-black, #000A1A);
            transition: border 0.3s ease-in;
        
        }
    }
}



.card2-body-link {
    text-decoration: none;
    border: none;
    color: var(--essential-black, #000A1A) !important;
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

}

.card2-container {
    margin: 5.5rem 0 0 0;
    position: relative;
    /* overflow: hidden; */
}

.card2-title {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    color: var(--essential-white, #FFF);
    font-family: DM Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: capitalize;
    z-index: 3;
    padding: 0.5rem;
}

.card2-body {
    display: none;
    margin-top: 1.25rem;
}

.card2-body a {
    color: var(--essential-black, #000A1A);
    margin: 0rem;
    text-align: justify;
}

.card2-body-text {

    color: var(--greyscale-shade-1, #52637A);

    /* Paragraph/P 1 */
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 0.62rem;
    /* 1.6rem */
}

.card2-img-overlay {
    display: none;
}

@media screen and (max-width: 767px) {
    .card2-img-container {
        flex-direction: column;
        margin-bottom: 0;
        height: 22rem;
    }

    .card2-title {
        font-size: 1.2rem;
    }

    .card2-container {
        &:hover {
            margin-bottom: 12rem;
            transform: translateY(0);
            transition: all 0.3s ease-in-out;

            .card2-body-title {
                margin-left: 1rem;
            }

            .card2-body-link {
                margin-left: 1rem;
            }

            .card2-body-underline {
                margin-left: 2.25rem;
                color: #fff;
            }

            .card2-body-text {
                display: block;

            }

            .card2-img-overlay {
                display: none;
            }
        }
    }

    .card2-container {
        margin: 0.5rem;

    }

    .card2-body-text {
        display: none;
        color: var(--greyscale-shade-1, #52637A);
        margin-left: 1.25rem;
    }

    .card2-img-overlay {
        position: absolute;
        display: block;
        color: var(--essential-white, #FFF);
        font-weight: 500;
        background-color: #52637a5c;
        padding: 1rem;
        border-radius: 2rem;

    }
}

.card2-container:hover img {
    height: 16.25rem;
    transition: height 0.3s ease-in-out;
}

.card2-overley {
    position: absolute;
    bottom: 0;
    height: 11.3125rem;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 10, 26, 0.00) 0%, rgba(0, 10, 26, 0.60) 100%);

    z-index: 2;
}