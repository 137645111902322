.hero-part {
    background-image: url('../../Images/hero-area-bg-small.webp');
    /* background-position: bottom; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 2rem; */
    height: 103vh;
    background-color: (--greyscale-shade-4, #F8F9FA);
    margin-top: 3rem;
}

.hero-padding {
    padding: 6rem;
}

.container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    /* margin-bottom: 10rem; */
}

.hero-text {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 62.5rem;
    ;
    color: var(--essential-white, #FFF);
    text-align: center;

    /* Heading/H 1 */
    font-family: DM Sans;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 5.5rem */
    text-transform: capitalize;
    margin-top: 7.5rem;
    /* margin-bottom: 1.5rem; */
}

.hero-text2 {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: var(--essential-white, #FFF);
    text-align: center;

    /* Title/T 1 */
    font-family: DM Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 2.1rem */
    margin-bottom: 3rem;
}

@media (max-width: 767px) {
    .hero-text {
        margin-top: 20%;
        font-size: 2.5rem;
        width: 80%;
    }

    .hero-text2 {
        font-size: 1.2rem;
        width: 80%;
        margin-bottom: 10%;
    }

    .hero-part {
        margin-bottom: 2.5rem;
    }
}